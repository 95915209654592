<template>
<div>
    <CNavbar expandable="md" class="bg-nav">
        <CToggler in-navbar @click="collapsed = !collapsed" />
        <CHeaderBrand class="mx-auto d-lg-none">
            <router-link :to="{ name: 'Panel de Control' }" class="router-link-none">
                <img :src="datoSession.logo" class="img-fluid mt-0 mb-0" width="150px" />
            </router-link>
        </CHeaderBrand>
        <CHeaderNav class="d-md-down-none mr-auto">
            <router-link :to="{ name: 'Panel de Control' }" class="router-link-none">
                <img :src="datoSession.logo" class="c-sidebar-brand-full img-fluid mt-2 mb-2" width="150px" />
            </router-link>
        </CHeaderNav>
        <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
                <div>
                    <b-dropdown size="lg" :class="currentRouteName == 'Comercial' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'COMERCIAL' }" style="inline-size: auto" class="fas fa-briefcase fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'Posibles clientes cliente' }">Posibles clientes</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Clientes cliente' }">Clientes</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Cotizaciones certificacion' }">Cotización de certificación</b-dropdown-item>
                        <b-dropdown-item>Cotización de homologación</b-dropdown-item>
                        <b-dropdown-item>Cotización de inspeccción</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Vendedores cliente' }">
                            <!-- <i class="fas fa-money-bill-alt mr-2 my-0"></i>  -->Vendedores</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown size="lg" :class="currentRouteName == 'Recursos Humanos' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'RECURSOS HUMANOS' }" style="inline-size: auto" class="fas fa-users fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'Puestos cliente' }">Puestos de trabajo</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Personal cliente' }">Personal</b-dropdown-item>
                    </b-dropdown>

                    <b-dropdown size="lg" :class="currentRouteName == 'Pack' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'PACK' }" style="inline-size: auto" class="fab fa-buffer fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'Check List de Informacion' }">
                            Información Inicial
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Documentos Stage 1' }">
                            Documentos STAGE 1
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Ingreso de documentos Stage 1' }">
                            Ingreso de documentos STAGE 1
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Documentos Stage 2' }">
                            Documentos STAGE 2
                        </b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'Ingreso de documentos Stage 2' }">
                            Ingreso de documentos STAGE 2
                        </b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown size="lg" :class="currentRouteName == 'Planeación' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'PLANEACIÓN' }" style="inline-size: auto" class="fas fa-cubes fa-2x drop-custom">
                                </i>

                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'Asignacion cliente' }">Asignación cliente</b-dropdown-item>
                        <!--	<b-dropdown-item :to="{ name: 'Nueva Auditoria' }">Nueva Auditoria</b-dropdown-item> -->

                    </b-dropdown>
                    <b-dropdown v-if="!!this.$store.state.modules.find((x) => x.identificador == '001-AUD')" size="lg" :class="currentRouteName == 'Auditorías' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'AUDITORÍAS' }" style="inline-size: auto" class="fas fa-book-reader fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'ejecutar auditoria' }">Ejecucion de auditoria</b-dropdown-item>

                    </b-dropdown>
                    <b-dropdown v-if="!!this.$store.state.modules.find((x) => x.identificador == '002-HOM')" size="lg" :class="currentRouteName == 'Homologación' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'HOMOLOGACIÓN' }" style="inline-size: auto" class="fas fa-book fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'homologacion index cliente' }">Index</b-dropdown-item>
                        <b-dropdown-item :to="{ name: 'homologacion index2 cliente' }">Index2</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown v-if="!!this.$store.state.modules.find((x) => x.identificador == '003-INS')" size="lg" :class="currentRouteName == 'Inspecciones' ? 'dropdown-active' : ''" variant="outline" toggle-class="text-decoration-none">
                        <template #button-content>
                            <div class="dropdown-icon">
                                <i v-tooltip.top="{ content: 'INSPECCIONES' }" style="inline-size: auto" class="fas fa-file-signature fa-2x drop-custom">
                                </i>
                            </div>
                        </template>
                        <b-dropdown-item :to="{ name: 'inspecciones index cliente' }">Index</b-dropdown-item>
                    </b-dropdown>
                </div>
            </CNavbarNav>
        </CCollapse>

        <CNavbarNav class="ml-auto">
            <ContentDropdown />
        </CNavbarNav>
    </CNavbar>
    <CSubheader class="px-3">
        <CBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
</div>
</template>

<script>
import ContentDropdown from './ContentDropdown';

export default {
    name: 'ContentHeader',
    components: {
        ContentDropdown,
    },
    data() {
        return {
            collapsed: false,
            datoSession: {
                logo: JSON.parse(localStorage.settings).logo,
            },
        };
    },
    created() {
        let primario = JSON.parse(localStorage.settings).primaryColor;
        let secundario = JSON.parse(localStorage.settings).secondaryColor;
        let accent = JSON.parse(localStorage.settings).accentColor;
        this.setThemeColors(primario, secundario, accent);
    },
    methods: {
        setThemeColors(primario, secundario, accent) {
            let style = document.documentElement.style;

            if (primario.includes('{')) {
                primario = JSON.parse(primario)
                style.setProperty('--bg-rotate', `${primario[0].rotate}turn`);
                for (let k in primario[0].colors) {
				   style.setProperty(`--bg-primary-color${k}`, primario[0].colors[k]);
                }
            } else {
                style.setProperty('--bg-rotate', `0.50turn`);
                style.setProperty('--bg-primary-color0', primario);
                style.setProperty('--bg-primary-color1', primario);
                style.setProperty('--bg-primary-color2', primario);
            }
            style.setProperty('--bg-secondary-color', secundario);
            style.setProperty('--bg-accent-color', accent);
        },
    },
    computed: {
        currentRouteName() {
            return this.$route.matched[1].name;
        },
    },

};
</script>

<style lang="scss">
.bg-primario {
    background: linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
    color: var(--bg-secondary-color) !important;
}

.modal-primario .modal-header {
    color: var(--bg-secondary-color) !important;
    background: linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));;
}

.card-accent-primario {
    border-top: 2px solid  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
}

.dropdown-active {
    background: rgba(0, 0, 0, 0.25) !important;
    // filter: brightness(85%);
    border-radius: 10px;
}

.dropdown-item.active,
.dropdown-item:active {
    background: linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
}

.bg-nav {
    background: linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
}

.table-custom thead th {
    color: var(--bg-secondary-color);
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
    border-bottom: 0px;
}


/* ------------------------------- */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: var(--bg-secondary-color) !important;
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
}

.nav-link {
    color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

.nav-link:hover {
    color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

/* ------------------------------- */

.vs__dropdown-option:hover {
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
    color: var(--bg-secondary-color) !important;
}

.vs__dropdown-option--highlight {
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */

a {
    text-decoration: none;
    background-color: transparent;
    color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

a:hover {
    background-color: transparent;
    color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

/* ------------------------------- */

.btn-custom {
    color:   var(--bg-primary-color1);
    background-color: #f3f3f3;
    border-color: #f3f3f3;
    //F9F9F9
}

.btn-custom:hover {
    color: var(--bg-secondary-color);
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
    border-color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

.btn-custom:focus,
.btn-custom.focus {
    box-shadow: 0 0 0 0px  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

.btn-custom.disabled,
.btn-custom:disabled {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
}

.btn-custom:active,
.btn-custom.active,
.show>.btn-custom.dropdown-toggle {
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
    background-image: none;
    border-color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
}

.dropdown-toggle:focus {
    box-shadow: 0 0 0 0;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    color: var(--bg-secondary-color) !important;
}

/* ------------------------------- */

.icon-custom .ico {
    color: #cfcfcf;
}

.icon-custom:hover .ico {
    color:  var(--bg-primary-color1) !important; 
}

/*--------- pagination ----------*/

.page-item.active .page-link {
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
    border-color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
    color: var(--bg-secondary-color) !important;
}

.page-link {
    color:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
}

/* ------------------------------- */

.bg-accordion,
.bg-accordion:focus {
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
    color: var(--bg-secondary-color);
    padding: 5px;
    outline: 0px !important;
}

.bg-accordion-transparent,
.bg-accordion-transparent:focus {
    background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2));
    color: var(--bg-secondary-color);
    filter: opacity(70%);
    padding: 5px;
    outline: 0px !important;
}

.drop-custom {
    color: var(--bg-secondary-color);
}

.drop-custom:hover {
    color: var(--bg-secondary-color);
    filter: brightness(80%);
}

.dropdown-toggle::after {
    display: none;
}

.show-arrow-down-dropdown-button>.dropdown-toggle-split::after {
    display: inline-block;
}

.dropdown-icon {
    font-size: 0.8rem;
}

.dropdown-icon:hover {
    font-size: 0.83rem;
}

/* .navbar.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
} */
</style><style lang="scss">
.tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
        // background: rgba(255, 255, 255, 0);
        // background:  linear-gradient(var(--bg-rotate), var(--bg-primary-color0), var(--bg-primary-color1), var(--bg-primary-color2)) !important;
        background: transparent !important;
        color: var(--bg-secondary-color) !important;
        font-size: 0.875rem;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        max-width: 250px;
    }

    &[x-placement^='top'] {
        .tooltip-arrow {
            border-width: 5px 5px 0 5px;
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^='bottom'] {
        margin-top: 10px;

        .tooltip-arrow {
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            left: calc(50% - 5px);
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    &[x-placement^='right'] {
        margin-left: 5px;

        .tooltip-arrow {
            border-width: 5px 5px 5px 0;
            border-left-color: transparent !important;
            border-top-color: transparent !important;
            border-bottom-color: transparent !important;
            left: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[x-placement^='left'] {
        margin-right: 5px;

        .tooltip-arrow {
            border-width: 5px 0 5px 5px;
            border-top-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            right: -5px;
            top: calc(50% - 5px);
            margin-left: 0;
            margin-right: 0;
        }
    }

    &[aria-hidden='true'] {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.15s;
    }
}
</style>
