<template>
  <CFooter :fixed="false" style="background: #FFFF;">
    <div >
      <!-- <span class="text-muted">SIG SOFTWARE</span> -->
      <span class="ml-1">&copy; {{anio}} IM&RA Core Business.</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'ContentFooter',
     data() {
        return {
          anio: moment().format('YYYY')
        }}
}
</script>
