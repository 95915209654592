<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
    <template #toggler>
        <CHeaderNavLink>
            <div class="c-avatar">
                <img src="img/perfil.png" class="c-avatar-img " />
            </div>
        </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
        <strong>{{usuario}}</strong>
    </CDropdownHeader>
    <!-- <CDropdownItem>
      <CIcon name="cil-bell"/> Actualizaciones
      <CBadge color="info" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Mensajes
      <CBadge color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-task" /> Tareas
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-comment-square" /> Comentarios
      <CBadge color="warning" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownHeader
      tag="div"
      class="text-center"
      color="light"
    >
      <strong>Ajustes</strong>
    </CDropdownHeader> -->
    <CDropdownItem :to="{name: 'Panel de Control'}">
        <i class="fas fa-home pr-1"></i> Inicio
    </CDropdownItem>
    <CDropdownItem :to="{name: 'Perfil Coordinador'}">
        <b-icon icon="person-fill"></b-icon> Perfil
    </CDropdownItem>
    <!-- <CDropdownItem>
        <b-icon icon="gear-fill"></b-icon>Ajustes
    </CDropdownItem> -->
    <!-- <CDropdownItem>
      <CIcon name="cil-dollar" /> Pagos
      <CBadge color="secondary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownItem>
      <CIcon name="cil-file" /> proyectos
      <CBadge color="primary" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
    <CDropdownDivider />
    <!-- <CDropdownItem>
      <CIcon name="cil-shield-alt" /> Bloquear cuenta
    </CDropdownItem> -->

    <CDropdownItem @click="cerrarSesion()">
        <b-icon icon="lock-fill"></b-icon> Cerrar sesión
    </CDropdownItem>
    
</CDropdown>
</template>

<script>
export default {
    name: 'ContentDropdown',
    data() {
        return {
                usuario: this.$store.state.user.username
        }
    },
    methods: {
        cerrarSesion() {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Cerrar Sesión',
                cancelButtonColor: '#1276e2',
                cancelButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    me.$router.push({
                        name: "Login Cliente",
                        params: {
                            rsCliente: JSON.parse(localStorage.settings).path
                        }
                    })
                    localStorage.clear();
                }
            })
        },
        irAPerfil() {
            this.$router.push({
                name: "Perfil Coordinador"
            })
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    mounted() {

      
    }
}
</script>

<style scoped>
.b-icon {
    margin-right: 0.3rem;
}
</style>
